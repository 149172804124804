import styled from "styled-components";

export const EditProjectTypeContainer = styled.div``;

export const Select = styled.select`
  padding: 0.5rem 2rem;
  background-color: #aaa;
  border: none;
  border-bottom: 1px solid #aaa;
  color: #444;
`;
